import React, { useEffect } from 'react';
import { gsap } from 'gsap';

function CustomCursor() {
  useEffect(() => {
    const handleMouseOver = () => {
      cursor?.classList.add('custom-cursor--link');
    };

    const handleMouseOut = () => {
      cursor?.classList.remove('custom-cursor--link');
    };

    const handleMouseMove = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      if (!initCursor) {
        gsap.to(cursor, 0.3, {
          opacity: 1
        });
        initCursor = true;
      }

      gsap.to(cursor, 0, {
        top: mouseY + 'px',
        left: mouseX + 'px'
      });
    };

    const handleMouseOutWindow = (e) => {
      gsap.to(cursor, 0.3, {
        opacity: 0
      });
      initCursor = false;
    };

    const cursor = document.querySelector('.custom-cursor');
    let initCursor = false;

    // Function to attach event listeners to all links
    const attachLinkListeners = () => {
      const links = document.querySelectorAll('a');
      links.forEach((selfLink) => {
        selfLink.addEventListener('mouseover', handleMouseOver);
        selfLink.addEventListener('mouseout', handleMouseOut);
      });
      return links;
    };

    // Initial attachment
    let links = attachLinkListeners();

    // Create a MutationObserver to watch for DOM changes
    const observer = new MutationObserver(() => {
      // Remove old listeners
      links.forEach((selfLink) => {
        selfLink.removeEventListener('mouseover', handleMouseOver);
        selfLink.removeEventListener('mouseout', handleMouseOut);
      });
      // Attach new listeners
      links = attachLinkListeners();
    });

    // Start observing
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseout', handleMouseOutWindow);

    return () => {
      links.forEach((selfLink) => {
        selfLink.removeEventListener('mouseover', handleMouseOver);
        selfLink.removeEventListener('mouseout', handleMouseOut);
      });

      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseout', handleMouseOutWindow);
      observer.disconnect();
    };
  }, []);

  return (
    <div className="custom-cursor">
      {/* Additional JSX content */}
    </div>
  );
}

export default CustomCursor;
