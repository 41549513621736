import React from 'react'

const Project2 = () => {
  return (
    <li className="mb-12">
        <div className="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
            <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
            <div className="z-10 sm:order-2 sm:col-span-6">
                <h3>
                    <a className="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-indigo-300 focus-visible:text-indigo-300  group/link text-base" href="https://mcubedauto-git-development-maksymzerkakhs-projects.vercel.app" target="_blank" rel="noreferrer" aria-label="Build a Mechanic Website">
                        <span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span>
                        <span>Mcubedauto <span className="inline-block">Website<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true"><path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd"></path></svg></span></span>
                    </a>
                </h3>
                <p className="mt-2 text-sm leading-normal">Developed a high-performance, SEO-optimized website for a mobile mechanic business using Next.js and Tailwind CSS. The site is designed to effectively showcase the mechanic’s services, ensuring a seamless user experience with fast load times and mobile responsiveness. In addition to the customer-facing website, the platform includes a custom dashboard powered by Supabase DB for secure and scalable data management and Upstash for optimized request caching and rate limiting, ensuring efficient performance and reduced database load. The dashboard allows the mechanic to create and manage customer invoices, track their status as paid, pending, or overdue, and gain real-time revenue insights by monitoring total earnings from completed and outstanding invoices. It also features a monthly revenue analytics section, providing clear financial trends over time. This integrated solution enhances operational efficiency, ensures data reliability, and optimizes performance for a seamless business management experience.</p>
            </div>
        </div>
    </li>
  )
}

export default Project2