import React from 'react'

const Background = () => {
  return (
    <section id='background' className='mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24' aria-label='Background'>
        <div className='sticky top-10 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0'>
            <h2 className='text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only'>Background</h2>
        </div>

        <div>
            <p className='mb-4'>I am a proficient front-end developer specializing in Next.js, React, and WordPress. My expertise lies in crafting high-performance, scalable web applications, integrating custom solutions that prioritize optimal user experience, web performance, and SEO.</p>
            <p className='mb-4'>Additionally, I have a strong background in SEO and performance optimization, gained through extensive experience in marketing agency environments. Each project is built with a data-driven approach to search engine optimization, enhancing visibility and driving organic traffic growth. I excel in deadline management, efficient task prioritization, and seamless collaboration with clients, designers, and developers. My ability to bridge the gap between design, development, and marketing objectives ensures the successful delivery of projects that align with business goals and drive measurable results.</p>
        </div>
    </section>
  )
}

export default Background