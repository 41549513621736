import React from 'react'

const Experience3 = () => {
  return (
    <li className="mb-12">
        <div className="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
            <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
            <header className="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2" aria-label="2018 to Present">2024 — Present</header>
            <div className="z-10 sm:col-span-6">
                <h3 className="font-medium leading-snug text-slate-200">
                    <div><a className="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-indigo-300 focus-visible:text-indigo-300  group/link text-base" href="https://www.applovin.com" target="_blank" rel="noreferrer" aria-label="Lead Engineer at Upstatement"><span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span><span>Frontend Developer · <span className="inline-block">AppLovin<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true"><path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd"></path></svg></span></span></a></div>
                    <div>
                        <div className="text-slate-500" aria-hidden="true">Front-end Developer | Web Performance & Automation Specialist</div>
                    </div>
                </h3>
                <p className="mt-2 text-sm leading-normal">Developed and implemented custom solutions to drive company objectives, including creating high-performance landing pages in HubSpot and integrating custom API endpoints to display multilingual WordPress blog content seamlessly. Designed and built a custom WordPress template based on design team specifications, including a custom job board powered by the Greenhouse API to dynamically showcase job listings. Spearheaded CI/CD automation by integrating WP Engine with GitHub, establishing streamlined deployment pipelines that automate code linting, minification, and gzip compression for peak performance. Standardized GitHub workflows to ensure smooth collaboration, reducing merge conflicts and deployment risks. Optimized website performance to meet Core Web Vitals for lightning-fast load speeds and a superior user experience. Led advanced tracking integrations via Google Tag Manager (GTM) and Google Cloud Platform (GCP), including server-side tracking to manage multiple clients and efficiently route data to marketing and analytics platforms. Additionally, configured cloud storage with regional replication for APAC and EU clients, ensuring optimized media delivery across global markets.</p>
                <ul className="mt-2 flex flex-wrap" aria-label="Technologies used">
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">WordPress</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">JavaScript</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">PHP</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">SCSS</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Tailwind CSS</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">CI/CD</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">MySQL</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Local by Flywheel</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">NPM</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Gulp</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Git</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">GitHub</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">WP Engine</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Google Cloud Platform</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Google Tag Manager</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Google Analytics 4</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">REST API</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">HubSpot</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Figma</div>
                    </li>
                </ul>
            </div>
        </div>
    </li>
  )
}

export default Experience3