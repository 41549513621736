import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTag = () => {
  const description = "Front-end developer specializing in Next.js, React, and WordPress, with expertise in crafting high-performance, scalable web applications. Experienced in SEO optimization, performance enhancement, and marketing agency environments. Focused on delivering user-centric solutions that drive measurable results.";
  
  return (
    <Helmet>
      <title>Maksym Zerkakh - Front-end Developer</title>
      <meta name="description" content={description} />
      
      {/* Google Fonts Optimization */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700;800&display=swap" rel="stylesheet" />
      
      {/* Essential META Tags */}
      <meta property="og:title" content="Maksym Zerkakh - Front-end Developer & SEO Specialist" />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://maksymzerkakh.com" />
      <meta property="og:image" content="https://maksymzerkakh.com/og-image.jpg" />
      
      {/* Twitter META Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Maksym Zerkakh - Front-end Developer & SEO Specialist" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://maksymz.com/twitter-image.jpg" />
      
      {/* Additional META Tags */}
      <meta name="keywords" content="front-end developer, Next.js developer, React developer, WordPress developer, SEO specialist, web performance optimization, JavaScript, web development, Maksym Zerkakh" />
      <meta name="author" content="Maksym Zerkakh" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://maksymzerkakh.com" />

      {/* Additional Professional Meta Tags */}
      <meta name="profession" content="Front-end Developer" />
      <meta name="specialty" content="Next.js, React, WordPress, SEO" />
      <meta name="expertise" content="Web Performance, SEO Optimization, Scalable Applications" />
    </Helmet>
  );
};

export default MetaTag; 