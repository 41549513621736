import React from 'react'

const Languages = () => {
  return (
    <li className="mb-12">
        <div className="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
            <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
            <div className="z-10 sm:col-span-12">
                <h3 className="font-medium leading-snug text-slate-200">
                <div><span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span><span>Languages</span></div>
                </h3>
                <ul className="mt-2 flex flex-wrap" aria-label="Technologies used">
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">HTML</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">SCSS</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">JavaScript</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">JQuery</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">PHP</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">MySQL</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">GraphQL</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Postgres</div>
                    </li>
                </ul>
            </div>
        </div>
    </li>
  )
}

export default Languages